import React from 'react';

import { MobileMenu, TMobileMenuItem } from './MobileMenu';
import { TAction } from '../../../types';

import type { TMegaMenuHeaderItem } from '../HeaderMegaMenu';

type TDropdownItem = Required<TMegaMenuHeaderItem>['dropDown'][number];

type TSubMobileMenuProps = {
  selectedMenuItem: TMegaMenuHeaderItem;
  onClickClose: () => void;
  onClickBack: () => void;
  onClickMenuItem: (item: TDropdownItem) => void;
  mobileActionButton?: TAction;
};

export const SubMobileMenu: React.FC<TSubMobileMenuProps> = ({
  selectedMenuItem,
  onClickClose,
  onClickBack,
  onClickMenuItem,
  mobileActionButton,
}) => {
  const subMenuItems = (selectedMenuItem.dropDown || []).map<TMobileMenuItem>(
    item => ({
      title: item.rowTitle,
      onClick: () => {
        if (!item.rowBody?.length) {
          return;
        }

        onClickMenuItem(item);
      },
      ...item,
    }),
  );

  return (
    <MobileMenu
      title={selectedMenuItem.title}
      menuItems={subMenuItems}
      onClickBack={onClickBack}
      onClickClose={onClickClose}
      level={2}
      mobileActionButton={mobileActionButton}
    />
  );
};
