import React from 'react';

import { MobileMenu, TMobileMenuItem } from './MobileMenu';
import { ReactComponent as IconCheckInDropDown } from '../../../../public/icons/icon-check-in-dropdown.svg';
import { TAction } from '../../../types';

type TLocale = {
  value: string;
  label: string;
  country: string;
};

type TLocalesMobileMenuProps = {
  locales: TLocale[];
  currentLocale?: string;
  title?: string;
  onClickClose?: () => void;
  onClickBack: () => void;
  onClickLocale: (index: number) => void;
  mobileActionButton?: TAction;
};

export const LocalesMobileMenu: React.FC<TLocalesMobileMenuProps> = ({
  locales,
  currentLocale,
  title,
  onClickClose,
  onClickBack,
  onClickLocale,
  mobileActionButton,
}) => {
  const localeMenuItems = locales
    .sort(({ value: locale }) => (locale === currentLocale ? -1 : 0))
    .map<TMobileMenuItem>((locale, index) => ({
      title: locale.label,
      onClick: () => {
        onClickLocale(index);
        onClickClose?.();
      },
      icon:
        locale.value === currentLocale ? (
          <IconCheckInDropDown className='aspect-square size-4 text-interface-1000' />
        ) : undefined,
    }));

  return (
    <MobileMenu
      title={title}
      menuItems={localeMenuItems}
      onClickBack={onClickBack}
      onClickClose={onClickClose}
      mobileActionButton={mobileActionButton}
    />
  );
};
