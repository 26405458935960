import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { MobileMenuList } from './MobileMenuList';
import { ReactComponent as IconChevronLeft } from '../../../../public/icons/icon-chevron-left.svg';
import { ReactComponent as IconCross } from '../../../../public/icons/icon-close.svg';
import { ReactComponent as IconGlobe } from '../../../../public/icons/icon-globe.svg';
import { EActionType, TAction } from '../../../types';
import { Button, EButtonVariant } from '../../atoms/Button';

export type TMobileMenuItem = {
  title: string;
  href?: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  onClick?: () => void;
};

type TMenuProps = {
  title?: string;
  logo?: ImageProps | null;
  onClickBack?: () => void;
  onClickClose?: () => void;
  onClickLocales?: () => void;
  level?: 2 | 3;
  menuItems?: TMobileMenuItem[];
  mobileActionButton?: TAction;
  homeLink?: string;
};

export const MobileMenu: React.FC<TMenuProps> = ({
  title,
  logo,
  menuItems,
  onClickClose,
  onClickBack,
  onClickLocales,
  mobileActionButton,
  homeLink,
}) => {
  const titleClassName =
    'flex-1 py-5 pr-4 ps-8 text-sm font-medium leading-normal text-control-600';

  return (
    <div className='flex h-screen flex-row bg-white'>
      <div className='flex w-14 flex-col justify-between bg-control-100'>
        {logo && !onClickBack && (
          <Image {...logo} className='box-content aspect-square size-6 p-4' />
        )}
        {!!onClickBack && (
          <button onClick={onClickBack} className='p-4'>
            <IconChevronLeft className='aspect-square size-6 text-control-600' />
          </button>
        )}
        {!!mobileActionButton &&
          mobileActionButton.type !== EActionType.LinksGroupType && (
            <Button
              {...mobileActionButton.props}
              children=''
              variant={EButtonVariant.Image}
            />
          )}
      </div>
      <div className='flex flex-1 flex-col gap-2'>
        <div className='flex flex-row items-start'>
          {title ? (
            homeLink ? (
              <Link
                className={titleClassName}
                href={homeLink}
                target='_self'
                title={title}
              >
                {title}
              </Link>
            ) : (
              <div className={titleClassName}>{title}</div>
            )
          ) : null}
          {!!onClickLocales && (
            <button className='p-4' onClick={onClickLocales}>
              <IconGlobe className='aspect-square size-6 text-control-600' />
            </button>
          )}
          <button className='p-4' onClick={onClickClose}>
            <IconCross className='aspect-square size-6 text-control-600' />
          </button>
        </div>

        <MobileMenuList menuItems={menuItems} />
      </div>
    </div>
  );
};
