import { toImageProps, toLinkProps } from '@front/shared/ds';
import { TMegaMenuHeaderItem } from '@front/shared/ds/HeaderMegaMenu';
import { HeaderDropdown } from '@shared/master-types';

import { useLocale } from '../../../../hooks';

type TUsePrepareSubMenu = ({
  rows,
}: {
  rows: HeaderDropdown['dropDownRow'];
}) => TMegaMenuHeaderItem['dropDown'] | undefined;

export const usePrepareSubMenu = (): TUsePrepareSubMenu => {
  const { locale: currentLocale } = useLocale();

  return ({ rows }: { rows: HeaderDropdown['dropDownRow'] }) =>
    rows?.map(row => {
      const rowBody = Array.isArray(row.rowBody)
        ? row.rowBody
        : row.rowBody?.[currentLocale];
      return {
        ...row,
        rowIcon: toImageProps(row.rowIcon),
        rowId: row?.id,
        rowBody: rowBody?.map(body => {
          return {
            ...body,
            id: body.id,
            icon: toImageProps(body.icon),
            link: Array.isArray(body?.link)
              ? body.link.map(singleLink => ({
                  ...toLinkProps(currentLocale, singleLink),
                  'aria-disabled': !!singleLink?.disabled,
                  text: singleLink.text,
                  dataGaId: singleLink?.dataGaId,
                }))
              : null,
          };
        }),
      };
    });
};
