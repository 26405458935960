import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React from 'react';

type TToplineProps = {
  showTitle: boolean;
  rowTitle?: string;
  rowIcon?: ImageProps | null;
  isLong?: boolean;
};

export const Topline: React.FC<TToplineProps> = ({
  showTitle,
  rowTitle,
  rowIcon,
  isLong,
}) => {
  return (
    <>
      {showTitle ? (
        <div className='flex h-6 w-full flex-row items-center gap-2'>
          {rowIcon && (
            <Image {...rowIcon} width={24} height={24} className='h-6 w-auto' />
          )}
          <h3 className='text-header-submenu-title text-sm font-medium uppercase leading-[17.5px] tracking-[0.25rem]'>
            {rowTitle}
          </h3>
        </div>
      ) : null}
      <hr
        className={clsx(
          'text-header-submenu-divider mb-4',
          isLong ? 'w-[calc(100%+1rem)]' : 'w-full',
          {
            'mt-10': rowTitle && !showTitle,
            'mt-6': !rowTitle && !showTitle,
            'mt-4': showTitle,
          },
        )}
      />
    </>
  );
};
