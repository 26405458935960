import { DEFAULT_LOCALE } from '@front/shared';
import {
  HeaderMegaMenu,
  TMegaMenuHeaderItem,
} from '@front/shared/ds/HeaderMegaMenu';
import { addJurisdictionSupport } from '@front/shared/utils';
import {
  Header as THeader,
  NavMenu as INavMenu,
  Locale,
} from '@shared/master-types';
import React from 'react';

import { useGetHeaderDropdownsQuery } from './hooks/useDropdownQuery';
import { usePrepareSubMenu } from './hooks/usePrepareSubMenu';
import { useActions } from '../../../features/actions/useActions.hook';
import { useLocale } from '../../../hooks';

export type THeaderConnectedConnectedProps = Extract<
  Required<THeader>['header'][number],
  { blockType: 'header-mega-menu' }
> & {
  locales?: Locale[];
  withNavMenu?: boolean;
  navMenuLogo?: INavMenu['logoIcon'];
  navMenuLogoTitle?: INavMenu['title'];
  pageId: string;
};
const HeaderMegaMenuConnected: React.FC<
  THeaderConnectedConnectedProps
> = props => {
  const {
    logoIcon,
    logoText,
    locales = [],
    menuItems,
    menuActions,
    withNavMenu,
    navMenuLogo,
    navMenuLogoTitle,
    languageText,
    customHomeLink,
    ctaSettings,
    pageId,
  } = props;

  const { locale: currentLocale } = useLocale();
  const prepareSubMenu = usePrepareSubMenu();

  const dropdownIds = menuItems
    .map(item => {
      if (item.dropDown && typeof item.dropDown === 'string') {
        return item.dropDown;
      }

      if (
        item.dropDown &&
        typeof item.dropDown === 'object' &&
        item.dropDown.id
      ) {
        return item.dropDown.id;
      }

      return null;
    })
    .filter(item => typeof item === 'string') as string[];

  const dropdownWithDepth = useGetHeaderDropdownsQuery({
    ids: dropdownIds,
    depth: 1,
    locale: currentLocale || DEFAULT_LOCALE,
  });

  const actionButtons = addJurisdictionSupport({
    actionList: useActions(menuActions),
    localeList: locales,
  });

  const menuItemsPrepared = menuItems.map(item => {
    const preparedItem: Partial<TMegaMenuHeaderItem> = {
      title: item.title,
    };

    if (item.id) {
      preparedItem.id = item.id;
    }

    if (item.dropDown) {
      const dropdown = dropdownWithDepth.data?.docs.find(
        doc =>
          typeof item.dropDown !== 'string' && doc.id === item.dropDown?.id,
      );

      if (!dropdown) {
        preparedItem.dropDown = [{ rowTitle: '', isLoading: true }];
        return preparedItem;
      }

      const rows = Array.isArray(dropdown?.dropDownRow)
        ? dropdown?.dropDownRow
        : dropdown?.dropDownRow?.[currentLocale];

      preparedItem.dropDown = prepareSubMenu({ rows });
    }

    preparedItem.dataGaId = item?.dataGaId;

    return preparedItem;
  });

  const [mobileActionButton] = addJurisdictionSupport({
    actionList: useActions(ctaSettings?.mobileActions),
    localeList: locales,
  });

  return (
    <HeaderMegaMenu
      logoText={logoText}
      languageText={languageText}
      navMenuLogoTitle={navMenuLogoTitle}
      id={pageId}
      customHomeLink={customHomeLink}
      logoIcon={navMenuLogo || logoIcon}
      menuItems={menuItemsPrepared as TMegaMenuHeaderItem[]}
      actions={actionButtons}
      locales={locales}
      withNavMenu={withNavMenu}
      mobileActionButton={mobileActionButton}
    />
  );
};

export default HeaderMegaMenuConnected;
