import { checkLocaleExist, getGaId } from '@front/shared/utils';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { DEFAULT_LOCALE } from 'front/shared/consts';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import SubMenu from './SubMenu';
import { THeaderMegaMenuProps, TMegaMenuHeaderItem } from './types';
import { ReactComponent as IconGmail } from '../../../../public/icons/components/headerMegaMenu/mail.svg';
import { ReactComponent as IconArrowUp } from '../../../../public/icons/icon-dropdown-16.svg';
import { ReactComponent as IconGlobe } from '../../../../public/icons/icon-globe.svg';
import { ReactComponent as IconMenuClosed } from '../../../../public/icons/icon-menu-closed-thin.svg';
import { ReactComponent as IconCheckInDropDown } from '../../../../public/icons/icon-check-in-dropdown.svg';
import { EActionType, TAction } from '../../../types';
import toImageProps from '../../../utils/toImageProps';
import { Button } from '../../atoms/Button';
import { DropdownMenu } from '../../atoms/DropdownMenu';
import { DropdownItem, DropdownOverlay } from '../../atoms/DropdownOverlay';
import { EDropDownItemRadius } from '../../atoms/DropdownOverlay/DropdownItem';
import { HorizontalLogo } from '../../atoms/HorizontalLogo';
import { ScrollIndicator } from '../../atoms/ScrollIndicator';
import {
  EScrollIndicatorBackground,
  EScrollIndicatorSize,
  EScrollIndicatorTheme,
} from '../../atoms/ScrollIndicator/constants';
import { B2brokerMobileMenu } from '../B2brokerMobileMenu';
import { useMedia } from 'react-use';
import { baseTailwindConfig } from '@front/shared';

const MOBILE_MENU_ANIMATION_MS = 500;

const framerMobileMenu = {
  initial: { x: '100%' },
  animate: { x: 0 },
  exit: { x: '100%' },
  transition: { duration: MOBILE_MENU_ANIMATION_MS / 1e3 },
};

const HeaderMegaMenu: React.FC<THeaderMegaMenuProps> = props => {
  const {
    logoIcon,
    logoText,
    languageText = 'Language',
    locales = [],
    menuItems = [],
    actions = [],
    customHomeLink,
    withNavMenu,
    navMenuLogoTitle,
    mobileActionButton,
    id,
  } = props;

  const mobileLogoText = navMenuLogoTitle || logoText;
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
  const router = useRouter();

  let customHomeLinkPrepared = customHomeLink;

  const pathPattern = useRouter().asPath.split('/')[1];

  const isLocale = checkLocaleExist({
    locale: pathPattern,
    localeList: locales,
  });
  const currentLocale = isLocale ? pathPattern : DEFAULT_LOCALE;

  const localeList = locales.map(l => ({
    value: l.locale,
    label: l.label,
    country: l.country,
  }));

  const dialogRef = useRef<HTMLDialogElement>(null);
  const [dialogOpened, setDialogOpened] = useState(false);

  const currentLocaleIndex =
    localeList.findIndex(s => s.value === currentLocale) || 0;

  const closeModal = (): void => {
    if (dialogRef.current && dialogRef.current.open) {
      document.documentElement.classList.remove('overflow-y-hidden');
      setDialogOpened(false);
      dialogRef.current.close();
    }
  };

  const homeUrlParts = [];
  if (router.query.locale && router.query.locale !== DEFAULT_LOCALE) {
    homeUrlParts.push(String(router.query.locale));
  }

  const homeUrl = `/${homeUrlParts.join('/')}`;

  if (currentLocale !== DEFAULT_LOCALE) {
    customHomeLinkPrepared = customHomeLink + currentLocale + '/';

    // locale slash guard: support absolute and relative paths
    if (
      !customHomeLinkPrepared.startsWith('http') &&
      !customHomeLinkPrepared.startsWith('/')
    ) {
      customHomeLinkPrepared = '/' + customHomeLinkPrepared;
    }
  }
  const toggleDocumentScroll = (disableScroll: boolean): void => {
    if (disableScroll) {
      document.documentElement.classList.add('overflow-y-hidden');
    } else {
      document.documentElement.classList.remove('overflow-y-hidden');
    }
  };

  const handleDialogControlClick = useCallback((): void => {
    if (dialogRef.current) {
      if (dialogRef.current.open) {
        setDialogOpened(false);

        setTimeout(() => {
          dialogRef.current?.close();
          toggleDocumentScroll(false);
        }, MOBILE_MENU_ANIMATION_MS);
      } else {
        dialogRef.current.showModal();
        setDialogOpened(dialogRef.current.open);
        toggleDocumentScroll(true);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      toggleDocumentScroll(false);
      document.documentElement.style.paddingRight = '';
    };
  }, [id]);

  const handleLocaleChange = useCallback(
    (localeIndex: number | null) => {
      if (localeIndex !== null) {
        closeModal();

        const nextLocale = localeList[localeIndex].value;

        if (nextLocale) {
          const newAsPath =
            router.asPath.startsWith(`/${currentLocale}/`) ||
            router.asPath === `/${currentLocale}`
              ? router.asPath.replace(currentLocale, nextLocale)
              : '/' + nextLocale + router.asPath;

          if (currentLocale !== nextLocale) {
            void router.push(newAsPath);
          }
        }
      }
    },
    [localeList, router, currentLocale],
  );

  const handleDialogClick = (
    e: React.MouseEvent<HTMLDialogElement, MouseEvent>,
  ): void => {
    if (e.target === dialogRef.current) {
      closeModal();
    }
  };

  const renderActionByType = function handleActionType({
    action,
    index,
    isMenuAction = false,
    useIcon = false,
  }: {
    action: TAction;
    index: number;
    isMenuAction?: boolean;
    useIcon?: boolean;
  }): React.JSX.Element {
    switch (action.type) {
      case EActionType.LinksGroupType:
        return (
          <DropdownMenu
            label={action.label || ''}
            dataGaId={getGaId(action.dataGaId || action.label, 'header')}
            Icon={IconArrowUp}
          >
            {action.actions?.map((actionItem, i) => {
              return handleActionType({
                action: actionItem,
                index: i,
                isMenuAction,
              });
            })}
          </DropdownMenu>
        );
      case EActionType.LinkType:
      case EActionType.ButtonType:
      default:
        return (
          <Button
            key={index}
            {...action.props}
            className={clsx({
              'h-auto min-h-10 min-w-[121px] whitespace-nowrap rounded-3xl bg-brand-500 py-[11.5px] text-sm leading-normal hover:bg-brand-500/80':
                !useIcon,
              'rounded-full bg-transparent p-2 hover:bg-[#0000000A]': useIcon,
            })}
            children={useIcon ? <IconGmail /> : action.props.children}
            data-ga-id={getGaId(action.dataGaId || action.text, 'header')}
          />
        );
    }
  };

  useEffect(() => {
    const handleRouteChangeComplete = (): void => {
      closeModal();
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  const menuItemsRef = useRef<HTMLUListElement | null>(null);
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (menuItemsRef.current) {
      const width = menuItemsRef.current.scrollWidth;
      setMenuWidth(width);
    }
  }, []);

  const homeLink = customHomeLinkPrepared ? customHomeLinkPrepared : homeUrl;

  useEffect(() => {
    const width = window.innerWidth - document.documentElement.clientWidth;
    setScrollbarWidth(width);
  }, []);

  const is2xl = useMedia(`(min-width: ${baseTailwindConfig.screens['2xl']})`);

  return (
    <div
      key={id}
      style={{
        marginRight: is2xl && scrollbarWidth > 0 ? -scrollbarWidth : 0,
      }}
      className={clsx(
        '2xl:group-hover:bg-header-background pointer-events-auto z-50 box-border h-14 bg-white transition-opacity duration-500 xl:h-20 2xl:min-w-[100vw]',
        withNavMenu ? 'border-header-divider border-b' : '',
      )}
    >
      <div className='xl:px-4 2xl:mx-auto 2xl:max-w-[1232px] 2xl:px-0'>
        <nav
          className={clsx(
            'relative flex h-14 items-center justify-between transition lg:py-5 xl:h-20',
          )}
        >
          {/* Desktop menu */}
          {logoIcon && (
            <div className='hidden w-full max-w-fit 2xl:block'>
              <Link href={homeLink} data-ga-id={getGaId('logo', 'header')}>
                <HorizontalLogo
                  hasBackLogo={!!withNavMenu}
                  logoIcon={logoIcon}
                  logoText={logoText}
                />
              </Link>
            </div>
          )}

          <div className='hidden overflow-hidden px-6 2xl:block 2xl:w-full 2xl:px-4'>
            <ul
              ref={menuItemsRef}
              className='scrollbar-hidden w-full max-w-fit flex-nowrap items-center self-center overflow-auto 2xl:mx-auto 2xl:flex 2xl:w-full 2xl:justify-center 2xl:gap-2'
            >
              {menuItems.map((menuItem, index) => {
                const gaId = getGaId(
                  menuItem.dataGaId || menuItem.title,
                  'header',
                );
                return (
                  <li key={index}>
                    <DropdownOverlay
                      label={menuItem.title}
                      menuWidth={menuWidth}
                      pageKeyId={id}
                      dataGaId={gaId}
                      chevronClassName='text-header-chevron min-w-4'
                      buttonClassName='!text-header-text-button hover:!bg-[#0000000A] whitespace-nowrap'
                    >
                      <SubMenu menuItem={menuItem} />
                    </DropdownOverlay>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={clsx(
              'hidden items-center justify-between gap-2 2xl:flex',
              {
                '2xl:flex-row-reverse': !withNavMenu,
              },
            )}
          >
            {actions && (
              <div className='flex items-center gap-1'>
                {actions.map((action, index) =>
                  renderActionByType({
                    action,
                    index,
                    isMenuAction: true,
                    useIcon: withNavMenu,
                  }),
                )}
              </div>
            )}

            <DropdownOverlay
              label=''
              Icon={IconGlobe}
              pageKeyId={id}
              isJurisdictionButton
              isOpenOnClick
              buttonClassName='hover:bg-[#0000000A]'
              iconClassName='text-header-icon'
            >
              <div className='grid grid-cols-6 gap-4 xl:mx-auto 2xl:box-content 2xl:w-full 2xl:max-w-[1232px]'>
                {localeList.map((locale, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      title={locale.label}
                      rounded={EDropDownItemRadius.Xxl}
                      isChecked={i === currentLocaleIndex}
                      checkedIcon={
                        <IconCheckInDropDown className='aspect-square size-4 text-interface-1000' />
                      }
                      onClick={() => handleLocaleChange(i)}
                    />
                  );
                })}
              </div>
            </DropdownOverlay>
          </div>

          {/* Mobile menu */}
          <div className='2xl:hidden'>
            <HorizontalLogo
              hasBackLogo={!!withNavMenu}
              className='p-4'
              logoIcon={logoIcon}
            />
          </div>

          {mobileLogoText && (
            <a
              className='text-header-logo-text text-base font-semibold 2xl:hidden'
              href='#_app'
              data-ga-id={getGaId('logo', 'header')}
            >
              {mobileLogoText}
            </a>
          )}

          <div className='flex items-center gap-3 2xl:hidden'>
            <button
              className='flex items-center justify-center p-4'
              onClick={handleDialogControlClick}
            >
              <IconMenuClosed className='aspect-square size-6 text-control-600' />
            </button>
          </div>

          <dialog
            ref={dialogRef}
            className='fixed h-[calc(100dvh)] max-h-none w-screen max-w-none overflow-hidden bg-transparent backdrop:bg-transparent 2xl:hidden'
            onClick={handleDialogClick}
            onClose={() => {
              toggleDocumentScroll(false);
            }}
          >
            <AnimatePresence initial={false}>
              {dialogOpened && (
                <motion.div {...framerMobileMenu}>
                  <B2brokerMobileMenu
                    logo={toImageProps(logoIcon)}
                    logoText={logoText}
                    localesHeader={languageText}
                    locales={localeList}
                    currentLocale={currentLocale}
                    homeLink={homeLink}
                    menuItems={menuItems as TMegaMenuHeaderItem[]}
                    onClickClose={handleDialogControlClick}
                    onClickLocale={handleLocaleChange}
                    mobileActionButton={mobileActionButton}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </dialog>
        </nav>
        {!withNavMenu && (
          <ScrollIndicator
            showOnlyInProgress
            rounded={false}
            background={EScrollIndicatorBackground.Transparent}
            theme={EScrollIndicatorTheme.LightBrand}
            size={EScrollIndicatorSize.Small}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderMegaMenu;
