import React from 'react';

import { TMegaMenuHeaderItem } from './types';
import { MenuCard } from '../../atoms/DropdownOverlay';

const SubMenu: React.FC<{
  menuItem: TMegaMenuHeaderItem;
}> = props => {
  const { menuItem } = props;

  if (!menuItem.dropDown) {
    return null;
  }

  return (
    <div className='grid grid-cols-4 gap-x-4 gap-y-12'>
      {menuItem.dropDown.map((dropDownGroup, i) => {
        return <MenuCard {...dropDownGroup} key={i} hasTopline />;
      })}
    </div>
  );
};

export default SubMenu;
